import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Plan from "@wisetack/shared-ui/components/Plan";
import {acceptOffer, getOffers} from "../store/actions/consumerActions";
import {formatAmount} from "@wisetack/shared-ui/utils/format";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {nextPageForStatus} from "./ConsumerEntryPage.js";
import {LoaderWithMessage} from "../components/LoaderWithMessage";

const pageName = "Offers Page";

class ConsumerChoosePlanPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.logProps = {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName,
    };
    logAmplitudeEvent(pageName, this.logProps);
    this.props.getOffers(this.props.loanAppId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.initExpired) {
      Log.info(this.props.initExpired, `initExpired`);
      this.props.history.push("/expired");
    }
    if (this.props.errorMessage) {
      Log.info(this.props.errorMessage, `error`);
      this.props.history.push("/error");
    }
    if (this.props.offerAcceptedAt !== prevProps.offerAcceptedAt) {
      // add logic here to route to another page depending from status
      switch (this.props.selectedLoanOfferStatus) {
        case "SELECTED":
          const lockRequired = this.props.lockRequired;
          if (lockRequired) {
            this.props.history.push("/offer_lock");
          } else {
            if ( this.props.status === "OFFER_AVAILABLE" || this.props.status === "CONDITIONAL_APPROVAL") {
              this.props.history.push("/link_bank");
            }
            else { //when reentering from same flow.
                   // Use same logic as entryPage. Be careful as it stays in choose_plan
              let page = nextPageForStatus(this.props);
              if(page[0]){
                 this.props.history.push(page[0]);
              }
            }
          }
          break;
        default:
          break;
      }
    }
  }

  handleOnPlanClick = (plan) => {
    Log.info(plan, `Plan clicked`);
    logAmplitudeEvent("Offer Selected", {
      ...this.logProps,
      offersCount: this.props.plans.length,
      offerSelectedDuration: plan.months,
      selectedInterestFreeOffer: plan.apr === 0,
    });
    this.props.acceptOffer(this.props.loanAppId, plan.id);
  };

  render() {
    const {
      status,
      isLoading,
      errorMessage,
      plans,
      expectedRunLength,
    } = this.props;

    let approvedAmount;
    if (plans) {
      approvedAmount = plans.map(p => p.approvedAmount).find(a => a != null);
    }

    const showContent = approvedAmount && status && !isLoading && !errorMessage;

    return (
        <Container>
          <PageHeader progress="40%">
            {showContent ? (
                <Fragment>
                  <div>
                    You’re{" "}
                    {status === "CONDITIONAL_APPROVAL" ? "qualified" : "approved"}{" "}
                    for {formatAmount(approvedAmount)}!
                  </div>
                  <div>
                    {status === "CONDITIONAL_APPROVAL" && (
                        <>
                          Your application isn't complete yet. <br/>
                        </>
                    )}
                    Choose an option.
                  </div>
                </Fragment>
          ) : null}
          {showContent ? (
            <div style={{ textAlign: "center" }}>
              No tricks: no origination fees,
              <br /> prepayment fees, or late fees.
            </div>
          ) : null}
        </PageHeader>
        <LoaderWithMessage isLoading={isLoading} duration={expectedRunLength}/>
        <Error pageName={pageName}>{errorMessage}</Error>
        <div style={{ paddingBottom: "15px" }}>
          {showContent &&
            plans.map((item) => (
              <Plan
                key={item.id}
                item={item}
                onClick={() => this.handleOnPlanClick(item)}
              />
            ))}
        </div>
      </Container>
    );
  }
}

ConsumerChoosePlanPage.propTypes = {
  getOffers: PropTypes.func.isRequired,
  acceptOffer: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired,
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  isLoading: state.consumer.isLoading,
  errorMessage: state.consumer.errorMessage,
  status: state.consumer.status,
  selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
  offerAcceptedAt: state.consumer.offerAcceptedAt,
  plans: state.consumer.plans,
  initExpired: state.consumer.initExpired,
  lockRequired: state.consumer.lockRequired,
  autoPaymentsDecision: state.consumer.autoPaymentsDecision,
  expectedRunLength: state.consumer.expectedRunLength,
});

export default connect(mapStateToProps, { getOffers, acceptOffer })(
  ConsumerChoosePlanPage
);
