import React from "react";
import classNames from "classnames";

import styles from "./LoanInfoItem.module.scss";

export function LoanInfoItem({item, onClick}) {
    const classes = {
        [styles.nav]: true,
        [styles.mint]: true
    }
    return <div onClick={onClick} className={styles.details}>
        <div className={classNames(classes)}>
            <span className={styles.amount}>{item.amount}</span>
            <span className={styles.delim}>|</span>
            <span className={styles.status}>{item.statusName}</span>
            <span className={classNames("material-icons", styles.open_icon)}>arrow_forward_ios</span>
        </div>
        <div className={styles.exp}>Exp. Date: {item.expDate}</div>
    </div>
}
