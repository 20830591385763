import React, {useEffect, useRef} from "react";
import {connect, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";

import Container from "@wisetack/shared-ui/components/Container";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";

import {getZip, prequalGet, prequalPatch} from "../store/actions/consumerActions";
import {PrequalInfoPageHeader} from "../components/PrequalInfoPageHeader";
import {PrequalInfoPageBottom} from "../components/PrequalInfoPageBottom";
import usePrequalFlow from "../hooks/usePrequalFlow";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import faker from "faker/locale/en_US";
import simulateMouseClick from "@wisetack/shared-ui/src/utils/click";
import {getAddressSuggestions} from "@wisetack/shared-ui/utils/SmartyStreetsApi";
import SmartyStreetsAutocomplete from "@wisetack/shared-ui/components/SmartyStreetsAutocomplete";
import {getPageActivity, trackAddressPageSubmission, trackPageActivity} from "../utils/addressPageActivity";

const pageName = "Prequal Address Page";

const validationSchema = Yup.object().shape({
    streetAddress1: Yup.string().required('Enter address').matches(/[a-zA-Z]+/, 'Please enter a valid address'),
    city: Yup.string().required('Enter city'),
    zip: Yup.string().required('Enter zip').matches(/(^\d{5}$)/, {message: 'Zip must be exactly 5 digits'}),
})

function PrequalAddressPage({getAddressSuggestions, prequalPatch, prequalGet}) {
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const fieldsValue = useSelector(state => state.consumer.fieldsValue);
    const suggestions = useSelector(state => state.consumer.suggestions);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');

    const sa1 = useSelector(state => state.consumer.fieldsValue["streetAddress1"]);
    //const fieldSource = useSelector(state => state.consumer.fieldsSource["streetAddress1"]);
    //const prefilledCount = useSelector(state => state.consumer.prefilledCount);

    const {applicationId, logAmplitude} = usePrequalFlow(prequalGet, newRequest);
    const [checkingZipCode, setCheckingZipCode] = React.useState({});
    const [autoCompleteLoading, setAutoCompleteLoading] = React.useState(false);
    const [autocompleteAvailable, setAutocompleteAvailable] = React.useState(true);
    const [streetAddress1Error, setStreetAddress1Error] = React.useState(false);
    const autoCompleteTimer = useRef(null);

    useEffect(() => {
        logAmplitude(pageName,null,{});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    const handleOnSave = (values) => {
        const data = {
            streetAddress1: values.streetAddress1,
            zip: values.zip,
            city: values.city
        }
        if (!!values.addressSecondaryNumber) {
            data.addressSecondaryNumber = values.addressSecondaryNumber;
        }
        prequalPatch(
            applicationId,
            data,
            newRequest()
        );
        trackAddressPageSubmission(data);
        logAmplitude(pageName, 'Address submitted', {
            pageActivity: getPageActivity()
        })
    }

    const formik = useFormik({
        initialValues: {
            streetAddress1: fieldsValue.streetAddress1 || "",
            addressSecondaryNumber: fieldsValue.addressSecondaryNumber || "",
            zip: fieldsValue.zip || "",
            city: fieldsValue.city || "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: handleOnSave
    });

    const zipMeta = formik.getFieldMeta("zip") || {};

    const setZipError = useRef(() => {
        formik.setFieldError("zip", "Invalid zip code");
        formik.setFieldTouched("zip", true, false);
        trackPageActivity("invalidZip");
        logAmplitude(pageName, 'Validation Error', {
            field: "Zip Code",
            value: zipMeta.value,
            message: "Invalid zip code"
        })
    })

    useEffect(
        () => {
            if (!zipMeta.error && zipMeta.value && zipMeta.value.length === 5) {
                setCheckingZipCode({inProgress: true, value: zipMeta.value});
                getZip(zipMeta.value).then(data => {
                    if (!data.state) {
                        setZipError.current();
                    }
                    setCheckingZipCode({inProgress: false, value: zipMeta.value});
                });
            }
        },
        [zipMeta.error, zipMeta.value],
    );

    return <Container>
        <PrequalInfoPageHeader progress="60%"/>
        <Form>
            <FormRow>
                <SmartyStreetsAutocomplete
                    value={sa1}
                    label={"Home address"}
                    loading={autoCompleteLoading}
                    options={suggestions ? suggestions : []}
                    onKeyUp={(e) => {
                        trackPageActivity("addressOnKeyUp", e);
                        clearTimeout(autoCompleteTimer.current);
                        formik.setFieldValue("streetAddress1", e.target.value);
                        setAutoCompleteLoading(autocompleteAvailable);
                        setStreetAddress1Error(false);
                        if (!autocompleteAvailable) {
                            return;
                        }

                        autoCompleteTimer.current = setTimeout(() => {
                            getAddressSuggestions(e.target.value, 7, (autocompleteAvailable) => {
                                setAutoCompleteLoading(false);
                                setAutocompleteAvailable(autocompleteAvailable)
                            })}, 400
                        );
                    }}
                    onChange={(e, v)=>{
                        if (!v) {
                            return;
                        }
                        trackPageActivity("autocompleteAddressSelected", e);
                        formik.setFieldValue("streetAddress1", (v.suggestion.street_line + " " + v.suggestion.secondary).trim());
                        formik.setFieldValue("city", v.suggestion.city)
                            .then(() => simulateMouseClick(document.getElementById("cityLabel")))
                            .then(() => formik.setFieldValue("zip", v.suggestion.zipcode)
                                .then(() => simulateMouseClick(document.getElementById("zipLabel")))
                                .then(() => simulateMouseClick(document.getElementById("addressSecondaryNumberLabel")))
                            );
                    }}
                    onBlur={() => {
                        let value = formik.getFieldProps("streetAddress1").value;
                        let error = !value || (/^[\d\W]+$/).test(value);
                        setStreetAddress1Error(error);
                        if (error) {
                            trackPageActivity("invalidAddress");
                        }
                    }}
                    error={streetAddress1Error}
                    stopRequest={() => clearTimeout(autoCompleteTimer.current)}
                />
            </FormRow>
            <FormRow>
                <FormInput
                    type="text"
                    name="addressSecondaryNumber"
                    label="Suite, Apt, Unit (optional)"
                    formik={formik}
                    blacklist={/[^a-zA-Z0-9\s/'"&#=:;,.+_!%)(|-]/g}
                    turnOffAutoFill={true}
                    onKeyUp={(e) => trackPageActivity("addressSecondaryNumberOnKeyPress", e)}
                />
            </FormRow>
            <FormRow>
                <FormInput
                    type="text"
                    name="city"
                    label="City"
                    formik={formik}
                    blacklist={/[^a-zA-Z0-9\s/'"&#=:;,.+_!%)(|-]/g}
                    autoFill={faker.address.city}
                    turnOffAutoFill={true}
                    onKeyUp={(e) => trackPageActivity("cityOnKeyPress", e)}
                />
            </FormRow>
            <FormRow>
                <FormInput
                    type="text"
                    max={5}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="zip"
                    label={checkingZipCode.inProgress ? 'checking...' : "Zip code"}
                    formik={formik}
                    blacklist={/\D/g}
                    autoFill="90001"
                    turnOffAutoFill={true}
                    onKeyUp={(e) => trackPageActivity("zipOnKeyPress", e)}
                />
            </FormRow>
        </Form>
        <LoaderWithMessage loading={!!requestId && requestInProgress}/>
        <Error pageName={pageName}>{requestError || errorMessage}</Error>
        <PrequalInfoPageBottom
            display={!requestInProgress}
            //btnDisabled={checkingZipCode.inProgress || formik.dirty || !formik.isValid}
            btnDisabled={checkingZipCode.inProgress || !formik.isValid}
            onButtonClick={formik.handleSubmit}
        />
    </Container>
}

export default connect(null, {getAddressSuggestions, prequalPatch, prequalGet})(
    PrequalAddressPage
);
