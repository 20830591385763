import moment from "moment"

export const formatMobileNumber = mobileNumber => {
    mobileNumber = mobileNumber.replace(/\D/g, "");
    if (mobileNumber.length === 10) {
        return "+1" + mobileNumber;
    } else if (mobileNumber.length === 11) {
        return "+" + mobileNumber;
    }
    return mobileNumber;
};

export const validateMobileNumber = mobileNumber => {
    if (!mobileNumber) return false;
    const number = mobileNumber.replace(/-/g, "");
    return (number.length === 10 || (number.length === 11 && number[0] === "1"));
};

export const formatExpDate = date => {
    if (!date) {
        return '';
    }
    if (moment(date, "MM/DD/YYYY").isValid()) {
        return moment(date, "MM/DD/YYYY").format('MMM DD, YYYY');
    }
    return '';
}

export const formatDate = (date, from, to) => {
    if (!date) {
        return '';
    }

    if (moment(date, from).isValid()) {
        return moment(date, from).format(to)
    }
    return '';
}

export const isBeforeCurrent = date => {
    if (!date) {
        return false;
    }
    return moment(date, "MM/DD/YYYY").isBefore(moment());
}