import React from "react";

const message001 = <p>
    Once you confirm this, we release funds to the merchant and your loan will begin.
    Your first payment will be due one month later.
</p>

const purchase_confirm_learn_more_p1_lg = <p>
    The final step is to let us know that you’ve agreed to the merchant’s services.
</p>

const purchase_confirm_learn_more_p2_lg = (settlementDelay) => {
    if (settlementDelay) {
        return <p>
            Once you confirm this, we will release funds to the merchant <b>{settlementDelay}</b> days later. Interest will not accrue until then, and your first payment will be due one month later.
        </p>
    }

    return message001;
}

const message002 = (expirationDate) => <p>
    {message003(expirationDate)} If you don’t confirm by then, you can reapply to get a new offer.
</p>

const message003 = (expirationDate) => <>
    Your loan offer is valid until <strong>{expirationDate}</strong>.
</>

export const getVerticalFragment = (messageId, vertical, data = {}) => {
    const task = (vertical === 'HS') ? 'job' : 'service'
    switch (messageId) {
        case 'review_plan_confirm':
            if (vertical === 'LG') {
                return <><b>Next Step</b>: Confirm to release funds.</>
            }
            return <><b>Next</b>: Confirm your {task} is done to start your&nbsp;loan</>
        case 'confirmation_header':
            if (vertical === 'LG') {
                return <>Last step: Confirm it's time to <br/> release payment</>
            }
            return <>Last step: Confirm your {task} is <br/> done to release payment</>
        case 'confirmation_message':
            if (vertical === 'LG') {
                if (data.settlementDelay) {
                    return <>
                        Confirm that you want to pay <strong>{data.merchantName}</strong>.
                        This will release their payment of  <strong>{data.approvedAmount}</strong>, and Wisetack will send funds <strong>{data.settlementDelay}</strong> days later.
                        If not, come back once you’re ready.&nbsp;
                    </>
                }
                return <>
                    Confirm that you want to pay <strong>{data.merchantName}</strong> now.
                    This releases their payment of <strong>{data.approvedAmount}</strong> and starts your loan.
                    If not, come back once you’re ready.&nbsp;

                </>
            }
            return <>
                If <strong>{data.merchantName}</strong> has finished the {task}, confirm below.
                This releases their payment of <strong>{data.approvedAmount}</strong> and starts your loan.
                If not, come back once the {task} is done.&nbsp;
            </>
        case 'release_payment_button':
            if (vertical === 'HS') {
                return 'JOB IS DONE - RELEASE PAYMENT'
            }
            if (vertical === 'LG') {
                return 'CONFIRM & RELEASE PAYMENT'
            }
            return 'DONE - RELEASE PAYMENT'
        case 'confirm_later_button':
            if (vertical === 'HS') {
                return 'JOB ISN’T DONE - CONFIRM LATER'
            }
            if (vertical === 'LG') {
                return 'CONFIRM LATER'
            }
            return 'NOT DONE - CONFIRM LATER'
        case 'confirmation_learn_more_header':
            if (vertical === 'HS') {
                return <>Confirming your job <br/> is done</>
            }
            return <>Confirming & releasing <br/> funds</>
        case 'confirmation_learn_more_message':
            if (vertical === 'HS') {
                return <>
                    <p>
                        The final step is to let us know the job is done to your satisfaction.
                    </p>
                    {message001}
                    <p>
                        If your job isn’t done yet, don’t worry. You can always come back and confirm later.
                        We’ll send you a reminder.
                    </p>
                    <p>
                        {message003(data.expirationDate)} If the job isn’t done by then, you can reapply to get a new offer.
                    </p>
                </>
            }
            if (vertical === 'LG') {
                return <>
                    {purchase_confirm_learn_more_p1_lg}
                    {purchase_confirm_learn_more_p2_lg(data.settlementDelay)}
                    {message002(data.expirationDate)}
                </>
            }
            return <>
                <p>
                    The final step is to let us know that you’re ready to release payment to the merchant for their services.
                </p>
                {message001}
                {message002(data.expirationDate)}
            </>
        case 'confirm_later_header':
            if (vertical === 'HS') {
                return <>Please come back later <br/> to confirm your loan</>
            }
            return <>Please come back later <br/> to release funds</>
        case 'confirm_later_message':
            if (vertical === 'HS') {
                return <>
                    <p>
                        Your loan won’t start until you confirm {data.merchantName} has finished the job.
                    </p>
                    <p>
                        Let us know when it’s scheduled to complete and we’ll send you a reminder to come back and confirm your loan.
                    </p>
                </>
            }
            if (vertical === 'LG') {
                return <>
                    <p>
                        Your loan won’t start until you confirm you’ve signed your service agreement to release payment.
                    </p>
                    <p>
                        Please come back once the service agreement is signed.
                    </p>
                </>
            }
            return <>
                <p>
                    Your loan won’t start until you confirm it’s <br/> time to release payment.
                </p>
                <p>
                    Please come back once it’s time to pay for the service(s).
                </p>
            </>
        case 'scheduled_completion_label':
            if (vertical === 'HS') {
                return 'Scheduled completion date (optional)'
            }
            if (vertical === 'LG') {
                return 'Scheduled agreement date (optional)'
            }
            return 'Scheduled payment date (optional)'
        case 'completion_error_past':
            if (vertical === 'HS') {
                return 'Completion date is in the past. If the job is done, click back to confirm your loan.'
            }
            if (vertical === 'LG') {
                return 'Agreement date is in the past. If the job is done, click back to confirm your loan.'
            }
            return 'Payment date is in the past. If the service is complete, click back to confirm your loan.'
        case 'completion_error_expired':
            if (vertical === 'HS') {
                return `Completion date is past your offer expiration of ${data.loanAppExpirationDate}. Consider reapplying later.`
            }
            if (vertical === 'LG') {
                return `Agreement date is past your offer expiration of ${data.loanAppExpirationDate}. Consider reapplying later.`
            }
            return `Payment date is past your offer expiration of ${data.loanAppExpirationDate}. Consider reapplying later.`
        case 'confirm_remainder_with_date_message':
            if (vertical === 'HS') {
                return <>
                    <p>
                        <strong>{data.merchantName}</strong> is scheduled to complete your job on <strong>{data.completionDate}</strong>
                    </p>
                    <p>
                        Your offer is still locked-in. We’ll send you an email and text reminder to confirm your loan once the work is done.
                    </p>
                </>
            }
            if (vertical === 'LG') {
                return <>
                    <p>
                        You are scheduled to sign your service agreement with <strong>{data.merchantName}</strong> on <strong>{data.completionDate}</strong>
                    </p>
                    <p>
                        Your offer is still locked-in. We’ll send you an email and text reminder to confirm release of funds once the service agreement has been signed.
                    </p>
                </>
            }
            return <>
                <p>
                    <strong>{data.merchantName}</strong> is scheduled to complete the services on <strong>{data.completionDate}</strong>
                </p>
                <p>
                    Your offer is still locked-in. We'll send you an email and text reminder to confirm your loan once the services are complete.
                </p>
            </>
        case 'confirm_remainder_without_date_header':
            if (vertical === 'HS') {
                return <>Great! Come back later to confirm&nbsp;your&nbsp;loan</>
            }
            return <>Great! Come back later to confirm&nbsp;and&nbsp;release&nbsp;funds</>
        case 'confirm_remainder_without_date_message':
            if (vertical === 'HS') {
                return <p>
                    Come back to confirm your loan after <strong>{data.merchantName}</strong> has completed the job.
                    We’ve sent you an email and text with a link.
                </p>
            }
            if (vertical === 'LG') {
                return <p>
                    Come back to confirm and release funds after you’ve signed the service agreement
                    with <strong>{data.merchantName}</strong>. We’ve sent you an email and text with a link.
                </p>
            }
            return <p>
                Come back to confirm and release funds after <strong>{data.merchantName}</strong> has completed the services.
            </p>
        case 'purchase_complete_header':
            if (vertical === 'HS') {
                return "Job isn't done yet?"
            }
            return "Didn’t mean to confirm?"
        case 'purchase_complete_message':
            if (vertical === 'HS') {
                return <>If <strong>{data.merchantName}</strong> hasn't finished the job yet, and you wish to stop funds, please contact </>
            }
            return <>If you wish to stop the funds from being sent to <strong>{data.merchantName}</strong>, please contact </>
        default:
            return ''
    }
}